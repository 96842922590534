import React, { Component } from "react"
import styles from "./footer.module.scss"

class Footer extends Component {
  render() {
    return (
      <footer className={styles.footer}>
        <a href="https://fernandopastorino.com" className={styles.footerText}>
          © {new Date().getFullYear()}
          {` `} Fernando Pastorino. All rights reserved.
        </a>
      </footer>
    )
  }
}
export default Footer
